<template lang='pug'>
layout-page
    div
        router-view
        div#vue


</template>

<script type="text/javascript">
import layoutPage from '@/layout/layout-page.vue'
export default {
  name: 'App',

  components: {
      layoutPage
  },
  
    provide () {
        return {
        reload: this.reload
        }
    },
    created(){

    },
    methods: {
        
 
    },
    


  data () {
    return {
       
    }
  }
}
</script>
