import { request, config } from '@/utils'

const api = {

    getLaunchList(params) {
        return request.post(`${config.yun_domain}/contract/package/list`,params)
    },

    //获取套餐购买记录接口
    getBuyMealList(params) {
        return request.post(`${config.yun_domain}/contract/package/purchased_list`,params)
    },

    //套餐购买接口
    buySetMeal(params) {
        return request.post(`${config.yun_domain}/contract/package/purchase`,params)
    },

    //获取应用订阅状态及剩余可用
    get_application_status(params) {
        return request.post(`${config.apiurl}/goods/get_application_status`,params)
    },
    //获取订单列表 
    get_order_list(params) {
        return request.post(`${config.yun_domain}/goods/get_order_list`,params)
    },
    //判断是否为旧套餐用户
    is_old_package_user(params) {
        return request.post(`${config.yun_domain}/goods/is_old_package_user`,params)
    },
    
   
}

export default api;