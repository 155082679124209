import {config,request} from '@/utils'

const api = {
    
    // 获取个人关联企业列表接口
    getCompanyList () { 
        return request.post(`${config.yun_domain}/api/v1/person_manage/related_company/list`)
    },
    // 切换个人关联企业接口
    companyChange (params) { 
        return request.post(`${config.yun_domain}/api/v1/person_manage/related_company/change`,params)
    },


}

export default api;