"use strict";
export default [

    // 未认证页面
    {
        path: '/no_certfy',
        name: 'NoCertfy',
        component: ()=>import('@/views/no_certfy/index.vue')
    },

    // 首页
    {
        path: '/',
        component: ()=>import('@/views/console/index.vue'),
        children: [
            {
                path: '',
                name: 'Console',
                component: ()=>import('@/views/console/index/index.vue')
            }
        ]
    },
    



    
 


];
