import '@/assets/style/base.less';
import '@/assets/style/common.less';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/iview/iview.js'
import { cookie } from '@/utils'
import * as axios from '@/utils/request'
import api from '@/api'
import mixin from '@/utils/pageMixin'
import { registerMicroApps, start ,initGlobalState,} from 'qiankun'
import { config } from '@/utils'
import ViewUI from "view-design"
import 'view-design/dist/styles/iview.css'
Vue.mixin(mixin)
Vue.config.productionTip = false
Vue.use(axios)
Vue.use(ViewUI);
Vue.prototype.$api = api;

window.eventBus = new Vue();
window._vue = null
window._global = {
  token: getQueryVariable('token')  || localStorage.getItem('token'),
  userInfo: {},
  env: process.env.NODE_ENV == "production"
}
function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
}
function  _isMobile() {
  let flag = navigator.userAgent.match( /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}



    localStorage.setItem('application',19)
    let token = getQueryVariable('token')  || localStorage.getItem('token') 
    let usertype = getQueryVariable('usertype')  || localStorage.getItem('usertype') 
    if(usertype !=3){
        localStorage.setItem('current_company_id',0)

    }
    if(getQueryVariable('token')){
        window.localStorage.setItem('token',getQueryVariable('token'))
        window.localStorage.setItem('usertype',getQueryVariable('usertype'))
        window.localStorage.setItem('current_company_id',getQueryVariable('current_company_id'))
    
    }
    if(!token){
   
        if (_isMobile()) {
            window.location.href =`${config.wdomain}`+'/acctlogin?referer_url=' + window.location.origin+'/wap'
           
        }else{
            window.location.href =`${config.domain}`+'/acctlogin?referer_url=' + window.location.origin
        }
    
    }else{
        if (_isMobile()) {
          
           window.location.href=window.location.origin+'/wap';
           
        }
        
        api.user.userInfo({usertype: Number(localStorage.getItem('usertype'))}).then(async  (res) => {
            if(res.status_code==0){
                localStorage.setItem('cert_status', res.obj.cert_status)
                localStorage.setItem('auth_data', JSON.stringify(res.obj.auth_data))
                localStorage.setItem('auth_info', JSON.stringify(res.obj.auth_info))
                _global.userInfo = res.obj
    
                if(_global.userInfo.usertype==1&&_global.userInfo.staff_id){
                    _global.userInfo.usertype=3
                    localStorage.setItem('usertype', 3)
                }
                siteInit()
    
            }
                
                
        })

    }

// 全局初始化
function siteInit () {
 // 通讯
 const actions = initGlobalState({    
  mt: '初始化state',// 初始化state，里面内容您随意
  id:'',
})
// 在项目中任何需要监听的地方进行监听，在这里监听是为了方便
actions.onGlobalStateChange((state,prev)=>{
    if(state.id){
        router.push("/noun/particulars?"+'id='+state.id)
    }

  console.log('main state change',state);
  console.log('已经监听到变化：',state);

  // this.$router.push({path:'/wap'})
})
// 将action对象绑到Vue原型上，为了项目中其他地方使用方便
Vue.prototype.$actions = actions



// 以上代码放在设置默认app之前即可


window._vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
// siteInit();
let apps = [
    

  //测试作品存证
  {
    name: 'certificate',
    // entry: 'http://172.16.1.245:8080/workstorage/ ',
    entry: '/workstorage/',
    // fetch,workstorage
    container: '#vue',   
    activeRule: '/cate',
    props:{id: ''}
  },
  //文件存证
  {
    name: 'categorizes',
    // entry: 'http://172.16.1.245:8088/fileinformation/',
    entry: '/fileinformation/',
    // fetch,fileinformation
    container: '#vue',   
    activeRule: '/archive',
    props:{id: ''}
  },
  {
    name: 'testimony',
    entry: '/evidenceinfo/',
    // entry: 'http://172.16.1.245:8089/evidenceinfo/',
    container: '#vue',   
    activeRule: '/noun',
  },
  //实名
  {
    name: 'verify',
    // entry: ' http://172.16.1.245:8082/acctuserverify/',
    entry: '/acctuserverify/',
    container: '#vue',   
    activeRule: '/acctuser',
  },
  {
    name: 'evidence',
    entry: '/evidencestore/',
    // entry: ' http://172.16.1.245:8081/evidencestore/',
    container: '#vue',   
    activeRule: '/tore',
    props:{id: ''}
  },
  //网页
  {
    name: 'webpage',
    entry: '/webstore/',
    // entry: 'http://172.16.1.245:8080/webstore/',
    container: '#vue',   
    activeRule: '/sever',
    props:{id: ''}
  },
  //合同
  {
    name: 'Contract',
    entry: '/acctuserfile/',
    // entry: 'http://172.16.1.245:8082/acctuserfile/',     
    container: '#vue',   
    activeRule: '/files',
  },



];

const configer = {
  beforeLoad: [
      app => {
          console.log("%c before load",
          'background:#0f0 ; padding: 1px; border-radius: 3px;  color: #fff',
          app);
      }
  ], // 挂载前回调
  beforeMount: [
      app => {
          console.log("%c before mount",
          'background:#f1f ; padding: 1px; border-radius: 3px;  color: #fff',
          app);
      }
  ], // 挂载后回调
  afterUnmount: [
      app => {
          console.log("%c after unload",
          'background:#a7a ; padding: 1px; border-radius: 3px;  color: #fff',
          app);
      }
    ] // 卸载后回调
  }
  registerMicroApps(apps, configer);
  let option = { prefetch: false }
  start();
}


