<template lang='pug'>
    .layoutbox
        .layoutbox-header
            //-.public.clearfix
                Headers
            .ctr
                .hd 
                    span 我的管理
                .content.clearfix
                    Dropdown.company(v-if="userInfo.usertype != 2")
                        .btn1(style="cursor:pointer")
                            p.tep {{userInfo.usertype == 1 ? '个人工作台' : companyName }}
                            div 
                                Icon.has-more(type="md-arrow-dropdown")
                        DropdownMenu.ivu-select(slot="list")
                            DropdownItem
                                a(@click="changePage(1)")  个人工作台
                            DropdownItem(v-for="item in companyList" :key="item.company_id") 
                                a(@click="changeCompany(item)") {{item.company_name}}
                    .finance(v-if="userInfo.usertype == 2")
                        a.finance-btn
                            | 财务
                            Icon(type="md-arrow-dropdown" size="14" color="#FFF")
                        .finance-content
                            .price 
                                | 账户余额
                                span {{userInfo.balance}}
                                | 元
                                a(:href="`${config.domain}/console/finance/recharge?token=${global.token}`+'&usertype='+`${usertype}`") 充值
                            .link 
                                a(:href="`${config.domain}/console/finance/extract?token=${global.token}`+'&usertype='+`${usertype}`") 转出
                                span | 
                                a(:href="`${config.domain}/console/finance/invoice?token=${global.token}`+'&usertype='+`${usertype}`") 开票
                            .line
                                a(:href="`${config.domain}/console/order?token=${global.token}`+'&usertype='+`${usertype}`") 我的订单
                            .line.bt 
                                a(:href="`${config.domain}/console/finance/info?token=${global.token}`+'&usertype='+`${usertype}`") 财务中心

                    //- 公司
                    Dropdown.company
                        .btn(href="javascript:void(0)") 
                            p.tep {{userInfo.realname}}
                            div 
                                Icon.has-more(type="md-arrow-dropdown")
                        DropdownMenu.ivu-select(slot="list")
                            DropdownItem
                                a(:href="`${config.domain}/console/account/info`+'?token='+`${global.token}`+'&usertype='+`${usertype}`") 账号信息
                            DropdownItem(v-if="userInfo.usertype == 2")
                                a(@click="handleSelectCompany") 切换企业
                            DropdownItem
                                a(@click="handleLoginout") 退出
                    .new(v-if="userInfo.usertype == 1")
                    //- 消息
                    .new(v-if="userInfo.usertype == 2")
                        a(:href="`${config.domain}/console/message`+'?token='+`${global.token}`+'&usertype='+`${usertype}`")
                        img(:src="`${publicPath}images/header/xiaoxi.png`")
                        span(v-if="userInfo.unread_message > 0") 
                            i {{userInfo.unread_message >= 100 ? '99+' : userInfo.unread_message}}

                


        .layoutbox-menu
            Menu(:open-names="menuOpenName" :active-name="$route.path" accordion ref="menu")
                div(v-for="(row, index) in routeList" :key="index")
                    Submenu(v-if="row.type == 'list' " :name="index")
                        template(slot="title") 
                            i.icon.icon-xiaoyuandian
                            span {{row.name}}

                        MenuItem(v-for="(v, i) in row.list" :key="i" :name="index + '-' + i") 
                            a(:href="`${config.domain}${v.path}`+'?token='+`${global.token}`+'&usertype='+`${usertype}`") {{v.name}}

                    MenuItem(v-else :name="index") 
                        a(style="padding-left: 24px!important;" :href="`${config.domain}${row.path}`+'?token='+`${global.token}`+'&usertype='+`${usertype}`") 
                            i.icon.icon-xiaoyuandian
                            span {{row.name}}
                div
                    Submenu( :name="'contract'")
                        template(slot="title") 
                            span 公信易
                        MenuItem(:name="'home'" )
                            router-link(:to="{name: 'Console'}" ) 首页
                        Submenu( :name="'service'")
                            template(slot="title") 存证服务
                            MenuItem(name="/cate/oups"  to="/cate/oups" v-if="auth_info['evidencestore_works'] !=0" )
                                .ice 作品存证
                            MenuItem( name="/archive/files"  to="/archive/files" v-if="auth_info['evidencestore_file'] !=0")
                                .ice 文件存证
                            MenuItem( name="/tore/serve"  to="/tore/serve" v-if="auth_info['evidencestore_contract'] !=0")
                                .ice 电子合同存证
                            MenuItem( name="/sever/extract" to="/sever/extract" v-if="auth_info['evidencestore_web'] !=0")
                                .ice 网页存证
                        MenuItem( name="/noun/evidence" to="/noun/evidence"  v-if="auth_info['evidenceinfo'] !=0")
                                .text 证据中心
        .layoutbox-content
            slot
        
        Modal(
            v-model="isModal" 
            footer-hide
            title="请选择企业账号" 
            class="modal" 
        )
            .modal-content.clear
                .item(
                    :class="[selected_company_id == v.company_id ? 'active' : '']"
                    v-for="(v, i) in userInfo.bind_company_list" 
                    @click="handleSelectCompanyId(v.company_id)" 
                ) {{v.company_name}}
        
        Modal(
            v-model="isModalCode" 
            footer-hide
            width="300"
            title="请填写验证码" 
            class="modal" 
            @on-cancel="code = '';company_id = selected_company_id;"
        )
            div
                .modal-group
                    Input(:value="userInfo.mobile.substr(0,3) + '****' + userInfo.mobile.substr(7)" size="large" disabled v-if="userInfo.mobile")
                .modal-group
                    Input(v-model="code" size="large" placeholder="请输入验证码")
                    Button(type="primary" @click="handleGetCode") {{code_text}}
                .modal-group
                    Button(class="btn" type="primary" @click="handelLoginCode" size="large") 确定

</template>
<script type="text/javascript">
import routeList from '../views/route-list.js'
import config from 'config'
import Headers from './headers-public.vue'
export default {
  name: 'LayoutPage',
  components:{Headers},
  watch: {
    // '$route' () {
    //   let { openName = ['contract','service'], activeName = 'home' } = this.$route.meta;
    //   this.menuOpenName = openName; //[2]
    //   this.menuActiveName = activeName; //'2-0'
    //   this.$nextTick(res => {
    //       this.$refs.menu.updateActiveName()
    //       this.$refs.menu.updateOpened()
    //   })
    // }
  },

  mounted () {
    this.routeList = routeList[this.userInfo.usertype == 2 ? 'company' : this.userInfo.usertype==1?'person':'staff'];
    let { openName = ['contract','service'], activeName = 'home' } = this.$route.meta;
    this.menuOpenName = openName; //[2]
    this.menuActiveName = activeName; //'2-0'
    this.userInfo.usertype != 2 ? this.getCompanyList() : ''
    this.$nextTick(res => {
        this.$refs.menu.updateActiveName()
        this.$refs.menu.updateOpened()
    })

    
  },

  methods: {
     qianyue(){
      this.$Message.error('企业账号暂不支持发起签约，请授权应用管理员，或由应用管理员指定员工发起签约')
    },
    handleGetUndohandls () {
        this.$api.archives.archivesHandleList({
            page: this.page,
            page_number: 1
        }).then(res => {
            if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
            this.undohandls = res.obj.undohandls
        }).catch(err => {
            console.log(err)
        })
    },

    handleLoginout () {
      if (this.loading) return;
      this.loading = true;
      this.$api.user.loginout().then(res => {
          this.loading = false
          if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
          window.location.href = config.domain
      }).catch(err => {
          this.loading = false
          console.log(err)
      })
    },

    // 切换企业
    handleSelectCompany  () {
      let company = this.userInfo.bind_company_list;
      if (company.length < 1) return this.$Message.info({content: '暂无可切换的企业账号'})
      this.isModal = true
    },
    handleSelectCompanyId (id) {
      if (id == this.company_id) return this.$Message.warning({content: '当前选择已经是该企业账户'});
      this.company_id = id; 
      this.isModalCode = true
    },
    handelLoginCode () {
      if(this.isAjax) return
      if (this.code == '') return this.$Message.warning({content: '验证码格式不正确'});
      this.isAjax = true
      this.$api.user.change_login({
        token: this.userInfo.token,
        company_id: this.company_id,
        code: this.code
      }).then(res => {
        this.isAjax = false
        if (res.status_code !== 0) return this.$Message.warning({content: res.status_msg});
        // cookie.setCookie('token', res.obj.token)
        localStorage.setItem('token', res.obj.token)
        setTimeout(() => {
            window.location.reload()
        }, 500)
      })
    },

    // 切换个人工作台
    changePage(type) {
      _global.userInfo.usertype = type;
      localStorage.setItem('usertype',type)
      window.location.href='/'
    },

    // 企业切换获取验证码
    handleGetCode () {
      if (this.isAjax || this.is_code_time) return
      this.isAjax = true;
      this.$api.user.sms({
          mobile: this.userInfo.mobile
      }).then((res) => {
          this.isAjax = false
          if (res.status_code != 0) return this.$Message.warning({content: res.status_msg});
          this.handleSetTime()             
      }, (err) => {
          this.isAjax = false;
      })
    },
    handleSetTime () {
      if (this.time == 0) {
        this.code_text = '获取验证码'
        this.is_code_time = false
        this.time = 60
        this.code = ''
        clearTimeout(this.timer);
        return;
      }
      this.is_code_time = true
      --this.time
      this.timer = setTimeout(() => {
        this.code_text = `${this.time}S`
        this.handleSetTime()
      }, 1000)
    },
    // 获取关联企业列表
    getCompanyList () {
      this.loading = true;
      this.$api.related_company.getCompanyList().then(res => {
          this.loading = false
          if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
          this.companyList = res.list
      }).catch(err => {
          this.loading = false
          console.log(err)
      })
    },
    // 切换员工工作台
    changeCompany(data) {
      this.isAjax = true
      localStorage.setItem('current_company_id',data.company_id)
      this.$api.related_company.companyChange({
        company_id: data.company_id,
      }).then(res => {
        this.isAjax = false
        if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
        localStorage.setItem('usertype',3)
        window.location.href='/'
      })
    }
  },

  data () {
    return {
      publicPath: process.env.BASE_URL,
      auth_info:JSON.parse(localStorage.getItem('auth_info')),
      config: config,
      global: _global,
      userInfo: _global.userInfo,
      undohandls: 0,
      menuActiveName: '',
      menuOpenName: [],

      isModal: false,
      isModalCode: false,
      company_id: '',
      selected_company_id: 0,

      code: '',
      code_text: '获取验证码',
      time: 60,
      timer: null,
      is_code_time: false,

      isAjax: false,
      isRouterAlive: true,
      routeList: [],
      companyName:_global.userInfo.company_name,
      companyList: [],
      usertype: Number(localStorage.getItem('usertype')),
    }
  }
}
</script>

<style lang="less" scoped>
.layoutbox                              {
  &-header                              {position: fixed;top: 0;left: 0;width: 100%;z-index: 100;
    .public                             {height: 30px;padding: 0 30px;background: #FFF;line-height: 30px;display: flex;
      .logo                             {
       display: flex;
        padding: 6px 0;
        width: 130px;
        .grc{
          margin-top: -7px;
          font-size: 14px;
          color: rgb(45, 67, 123);
          font-family: 微软雅黑;
          font-weight: 600;
        }
        img                             {
          width: 54px;
          height: 20px;
          }
      }
      .nav                              {margin-left: 20px;
        a                               {font-size: 12px;color: #333;display: inline-block;margin-left: 14px;}
      }
    }
    .ctr                                {height: 54px;background: #004481;
      .hd                               {color: #FFF;height: 54px;width: 216px;padding-left: 36px;background: #003a6c;float: left;
        span                            {font-size: 16px;line-height: 54px;}
      }
    }
    // .content                            {float: right;display: inline-flex;height: 54px;width: 380px;align-items: center;
    .content                            {float: right;display: inline-flex;height: 54px;width: 380px;align-items: center;justify-content:flex-end;padding-right:30px;
      .vip                              {display: flex;align-items: center;justify-content: center;padding: 0 16px;background: #FFF;border-radius: 4px;white-space: nowrap;line-height: 32px;
        img                             {width: 16px;margin-right: 2px;}
        div                             {font-size: 12px;color: #333;}
        &:hover div                    {color: @primary;}
      }

      .finance                          {float: left;width: 100px;height: 54px;position: relative;z-index: 100;
        &-btn                           {display: block;line-height: 54px;color: #FFF;text-align: center;}
        &-content                       {position: absolute;top: 54px;right: 0;background: #FFF;z-index: 1;padding: 10px 25px;box-shadow: 0 2px 3px #ddd;border: 1px solid #d9d9d9;display: none;
          div                           {color: #343434;width: 354px;display: block;line-height: 20px;margin: 14px 0;
            &.price                     {
              span                      {font-size: 24px;margin: 0 6px;font-weight: 600;}
              a                         {float: right;}
            }
            &.link                      {
              a                         {color: #343434;
                &:hover                 {color: @primary;}
              }
              span                      {margin: 0 14px;color: #999;display: inline-block;}
            }
            &.line                      {
              a                         {color: #343434;
                &:hover                 {color: @primary;}
              }
              &.bt                      {border-top: 1px solid #d9d9d9;padding-top: 14px;}
            }
          }
        }
        &:hover                         {background: #003a6c;
          .finance-content              {display: block;}
        }
      }
      .company                          {
        .btn                            {height: 54px;max-width: 200px;
        line-height: 54px;color: #FFF;padding: 0px 10px;display: flex;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
      
          &:hover                       {background: #003a6c;}
          .tep{
          height: 54px;line-height: 54px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
          }
        }
        // .btn1                            {height: 54px;width: 130px;
        .btn1                            {height: 54px;
        line-height: 54px;color: #FFF;padding: 0px 10px;display: flex;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
      
          &:hover                       {background: #003a6c;}
          .tep{
          height: 54px;line-height: 54px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
          }
        }
        .has-more                       {height: 54px;}
        .ivu-select                     {
          .ivu-select-dropdown          {border-radius: 0!important;margin: 0!important;padding: 0!important;}
          a                             {color: #343434;text-align: center;height: 22px;line-height: 22px;display: block;}
        }
      }
      .new                              {height: 54px;width: 54px;display: flex;align-items: center;justify-content: center;position: relative;
        img                             {width: 24px;}
        span                            {position: absolute;top: 10px;right: 0px;min-width: 20px;height: 12px;border-radius: 30px;font-size: 10px;background: red;color: #FFF;padding: 0 4px;line-height: 12px;
          i                             {transform: scale(0.8)!important;font-style: normal;font-size: 12px!important;line-height: 12px;}
        }
      }
    }
  }

  &-menu                                {position: fixed;top: 54px;left: 0;width: 216px;bottom: 0;background: #f5f5f5;box-shadow: 0 0 4px #d9d9d9;}

  &-content                             {padding: 54px 0 0 216px;
    // > div                               {overflow: auto;width: 100%;}
  }
}

.number                 {position: absolute;top: 6px;right: 86px;display:inline-block;min-width: 22px;height: 18px;background: red;color: #FFF;border-radius: 8px;font-size: 12px;text-align: center;}

.modal                  {
    // &-content           {max-height: 400px;overflow-y: scroll;}
    .item               {display: block;margin-bottom: 6px;padding: 4px 10px;cursor: pointer;font-size: 14px;
        &:hover         {color: #57a3f3;}
        &.active        {color: #57a3f3;}
    }
    &-group             {display: flex;margin-bottom: 15px;
        input           {flex: 1;}
        button          {width: 130px;margin-left: 10px;
            &.btn       {width: 100%;margin: 0;}
        }
    }
}
.ice{
    padding: 10px 24px 10px 75px;
}
.text{
    padding: 10px 24px 10px 43px;
}
</style>

<style lang="less">
.ivu-menu{
    background: none!important;
    width: 206px!important;
    margin: 0 5px!important;
    .router-link-exact-active{
        background: #005aa9 !important;
        color: #FFF!important;
        border-radius: 4px!important;
    }
    ul{
        margin: 0!important;
    }
    .ivu-menu-submenu-title > i,.ivu-menu-submenu-title span > i{
        margin-right: -8px;
        font-size: 18px;
        color: #999;
        &.icon{
            margin: 0!important;
            font-size: 14px;
            margin-right: 4px;
            }
    }
    .icon{
        margin: 0!important;
        font-size: 14px;
        margin-right: 4px;
    }
}
.ivu-menu-vertical.ivu-menu-light:after{
    display: none!important;
}
.ivu-menu-vertical .ivu-menu-submenu-title{
    color:#343434!important;
}
.ivu-menu-vertical .ivu-menu-item{
    color:#343434!important;
    padding: 0!important;
    border-radius: 4px!important;
}
.ivu-menu-vertical .ivu-menu-item a, .ivu-menu-vertical .ivu-menu-submenu-title a {
    color: #343434;
}

.ivu-menu-vertical .ivu-menu-item:hover{
    background: #e0e6ea;
}
.ivu-menu-vertical .ivu-menu-item a{
    padding: 10px 24px 10px 43px!important;
    display: block;
}
.ivu-menu-submenu .ivu-menu-item a {
    color: #343434;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
    // background: #e0e6ea !important;
    background: #005aa9 !important;
    color: #FFF!important;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {
    display: none!important;
}
.ivu-menu-vertical .ivu-menu-item:hover, .ivu-menu-vertical .ivu-menu-submenu-title:hover {
    background: #e0e6ea;
}

</style>