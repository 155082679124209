
const config = {
    development: {
        apiurl: 'http://gongxinyi.service.consul/gongxinyiApi',
        yun_domain: 'http://yun.service.consul',
        domain: 'http://yun15.service.consul',//登录
        wdomain: 'http://yunm.service.consul',
        wenhui: "https://wenhui.renshihr.cn",
        

        shiquanshimei: 'http://ippt8.service.consul',
      
        env: 'dev'
    },
    local: {
        apiurl: 'http://gongxinyi.service.consul/gongxinyiApi',
        yun_domain: 'http://yun.service.consul',
        domain: 'http://yun15.service.consul',//登录
        wdomain: 'http://yunm.service.consul',
        wenhui: "https://wenhui.renshihr.cn",
        shiquanshimei: 'http://ippt8.service.consul',
        env: 'local'
    },
    test: {
        apiurl: 'http://gongxinyi.renshihr.cn/gongxinyiApi',
        yun_domain: 'https://tpa.renshihr.cn',
        domain: 'https://t.renshihr.cn',//登录
        wdomain: 'http://m.renshihr.cn',
        wenhui: "https://wenhui.renshihr.cn",      //原文荟
        shiquanshimei: 'http://ippt8.renshihr.cn',
        env: 'test'
    },
    production: {
        apiurl: 'https://gongxinyi.renshihr.com/gongxinyiApi',
        yun_domain: 'https://api.renshihr.com',
        domain: 'https://renshihr.com',//登录
        wdomain: 'https://m.renshihr.com',
        wenhui: "http://ywh.renshihr.com",  //原文荟
        shiquanshimei: 'https://www.ippt8.com',
        env: 'production'
    }
}
console.log(process.env.VUE_APP_CONFIG)
module.exports = config[process.env.VUE_APP_CONFIG || 'test']