<template lang='pug'>
.headers-public
    .plic
        a.logo-box.float-left(:href="`${config.domain}`" target="_self")
            img.logo(
                src="../../public/images/header/headers-logo.png"
            )
            .divwer( style='float: right;')
                span(style='font-size: 14px;color: rgb(45, 67, 123);font-family: 微软雅黑;font-weight: 800;margin-left:5px') — 公信易 
        .float-left(style='margin-left:87px;font-size:15px')
            ul
                
                li.float-left
                    ul.nav 
                        li.li( @click='jumpHome("/")')
                            .lable 首页
                        li.li.relat 
                            .select-box.lable 软件应用
                                img.h-icon(src="../../public/images/header/hide.png")
                                img.s-icon(src="../../public/images/header/show.png")
                            .mask 
                            .hide-box
                                .title 专业应用
                                    img(src="../../public/images/header/hot.png")
                                ul
                                    li(@click='jumpHome("/electronicSignature")')    
                                        img(src="../../public/images/header/1.png")
                                        | 电子签约
                                    li(@click='jumpHome("/easyPay")')    
                                        img(src="../../public/images/header/3.png")
                                        | 发薪管理
                                    li(@click='jumpHome("/dateManagement")')    
                                        img(src="../../public/images/header/5.png")
                                        | 日程管理
                                    li(@click='jumpHome("/enterpriseDocuments")')    
                                        img(src="../../public/images/header/6.png")
                                        | 企业发文
                                    li(@click='jumpHome("/enterpriseNetDisk")')    
                                        img(src="../../public/images/header/4.png")
                                        | 共享网盘
                                    li(@click='jumpHome("/notaryCertificate")')    
                                        img(src="../../public/images/header/8.png")
                                        | 公证存证
                                    li(@click='jumpHome("/fileManagement")')    
                                        img(src="../../public/images/header/7.png")
                                        | 档案管理
                                    li(@click="goUrl(2)")    
                                        img(src="../../public/images/header/2.png")
                                        | 知识变现
                                    li(@click='jumpHome("/jobRecruitment")')    
                                        img(src="../../public/images/header/zhaopi.png")
                                        | 招聘管理
                        li.li(@click='goUrl(1)')
                            .lable 知产平台
                        li.li.relat 
                            .select-box.lable 企业服务
                                img.h-icon(src="../../public/images/header/hide.png")
                                img.s-icon(src="../../public/images/header/show.png")
                            .mask
                            .hide-box.com-app 
                                ul
                                    li 财税服务
                                    li 咨询服务
                                    li 招聘服务
                                    li 人事服务
                                    li 培训服务
                                    li 外包服务
                        li.li 
                            .lable(@click="jumpHome('/aboutUs')") 关于我们
                                    
                                    
                   
  

</template>
<script type="text/javascript">
import config from 'config'

  
    
    
    export default {
     
        methods: {
            goUrl(num) {
                if (num == 1) {
                
                window.open(`${config.shiquanshimei}`+`?token=${localStorage.getItem('token')}`)
                // window.open(config.shiquanshimei+`?token=${this.$user.token}`)
            }
            if (num == 2) {
                // if (this.$store.state.loginStatus) {
                    // window.open(config.wenhui+`?token=${this.$user.token}`)
                // } else {
                    // window.open(config.wenhui)   
                    window.open(`${config.wenhui}`+`?token=${localStorage.getItem('token')}`)
                // }
            }
        
            },
            jumpHome(link) {
                window.location.href = `${config.domain}`+link;
        }
            
        
        },
        data () {
            return {
                isModal: false,
                selected_company_id: 0,
                theme1:'light',
                isFor:false,
                config: config,
                pathc:'',
            }
        }
    }
</script>

<style scoped lang="less">

.headers .headers-public {
    height: 40px;
    background-color: #fff;
}
.plic{
    display: flex;
    align-items: center;
}
.logo-box{
    display: flex;
    align-items: center;
    // height: 30px;padding: 0 30px;background: #FFF;line-height: 30px;
    .logo{
        width: 54px;
        height: 18px; 
    }

}

.headers .headers-public .public-nav {
    height: 30px;
}
.public-nav{
    margin-left: 150px;
    li{
        a{
            font-size: 18px
        }
    }
}
.drop-down{
    min-width: 150px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 16px;
   
}
 .b{
      display: flex;
      align-items: center;
      justify-content: center;  
}
.drop-t-name{
    margin: 5px 20px;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E6F8F0;
}
ul.nav {
    @media screen and (max-width: 940px) {
        display: none;
        // background: turquoise;
    }
    display: flex;
    .li {
        margin-right: 2.083vw;
        transition: 0.3s;
        color: #20242b;
        & /deep/ a {
            color: #20242b;
        }
        .mask {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            height: 100px;
            display: none;
            &:hover {
            }
        }
        &:hover .mask {
            display: block;
        }
        &:hover .lable {
            color: #2b68e7;
            cursor: pointer;
        }
        &.relat {
            position: relative;
            .select-box {
                display: flex;
                align-items: center;
                img {
                    width: 10px;
                    margin-left: 7px;
                    transition: 0.3s;
                }
            }

            .hide-box {
                visibility: hidden;
                opacity: 0;
                transition: 0.3s;
                z-index: 8888;
                position: absolute;
                left: 10px;
                top: 50px;
                padding: 10px 30px 20px;
                background: #fff;
                border-radius: 5px;
                box-shadow: 0px 10px 30px 0px rgba(0, 37, 102, 0.1);
                width: 600%;

                .title {
                    display: flex;
                    align-items: center;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #dadada;
                    margin-bottom: 10px;
                    img {
                        margin-left: 5px;
                        width: 30px;
                    }
                }
                > ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    > li {
                        display: flex;
                        // padding: 10px 0px;
                        padding-right: 36px;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                            color: #2b68e7;
                        }
                        img {
                            width: 20px;
                            margin-right: 5px;
                        }
                    }
                }
                &.com-app {
                    width: 300%;
                    padding: 20px 20px;
                    > ul {
                        > li {
                            padding: 0px 10px;
                        }
                    }
                }
            }
            &:hover .hide-box {
                left: 0px;
                visibility: visible;
                opacity: 1;
            }
            .s-icon {
                visibility: hidden;
                opacity: 0;
                transform: rotate(180deg);
                transition: 0.3s;
                display: none;
            }
            &:hover .s-icon {
                visibility: visible;
                opacity: 1;
                transform: rotate(0deg);
                display: block;
            }
            &:hover .h-icon {
                display: none;
                visibility: hidden;
                opacity: 0;
            }
        }
    }
}

</style>