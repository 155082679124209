export default{
    data() {
        return {
            _loading: false
        }
    },
    methods: {
        errModel(content){
            this.$Message.error({content})
        },

        // 展示loading 需要配合destroy才能隐藏
        showLoading(content){
            this.$Message.loading({content: content||'提交中...',duration: 0});
        },

        httpStatus(res) {
            return new Promise((resolve,resject)=>{
                if(res.status_code == 0){
                    resolve()
                }
                else{
                    this.$Message.error({content: res.status_msg})
                    // this.errModel(res.status_msg)
                }
            })
      
        },
        
        // 隐藏loading
        hideLoading() {
            this.$Message.destroy()
        }
    },
}