"use strict";
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { config } from '@/utils'
Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach(async (to, from, next) => {
    console.log(to)
    
    if(to.name!='Console'&&to.path!="/no_certfy") {
        if(_global.userInfo.usertype == 2 &&_global.userInfo.cert_status !=99){
            if(to.path=='/acctuser/firm/approve'){
                return next();
            }else{
                return next('/no_certfy')
            }
        }

        if(_global.userInfo.usertype ==1 &&_global.userInfo.cert_status !=99) {
            if(to.path=='/acctuser'){
                return next();
            }else{
                return next('/no_certfy')
            }
        }
        if(_global.userInfo.usertype ==3 &&_global.userInfo.cert_status !=99){
                return next('/no_certfy')
        } 
    }

    return next();

})

export default router;

