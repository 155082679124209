import axios from 'axios'
import { config, cookie } from '@/utils'

axios.defaults.timeout = 100000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'//'application/json; charset=utf-8'; //'application/x-www-form-urlencoded';  
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

// 添加请求拦截器
axios.interceptors.request.use((req) => {
    let usertype =Number(localStorage.getItem('usertype'))
    
     let current_company_id=Number(localStorage.getItem('current_company_id'))
    
    
    if (_global.token && req.method == 'post') req.data = { token: _global.token,usertype,current_company_id, ...req.data }
    if (_global.token && req.method == 'get') req.params = { token: _global.token,usertype,current_company_id, ...req.params }
    return req;
}, (error) => {
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    if (response.status == 200) {

        if (response.data.status_code == 99999 || response.data.status_code == 5001) {    // 登录过期
            cookie.delCookie()
            localStorage.clear()
            // window.location.href = 'http://172.16.1.206:8006/acctlogin?referer_url=' + window.location.origin
            window.location.href =`${config.domain}`+'/acctlogin?referer_url=' + window.location.origin
            return 
        }
    }
    // else if(response.data.status_code==1){
    //     _vue.$Message.error({content: response.data.status_msg});
    // }
    else {
        _vue.$Message.destroy()
        _vue.$Message.error({content: code >= 500 ? '服务器发生错误，请稍后再试！' : '请求发生错误，请检查网络是否连接'});
        return false
    }
    // if(response.data.status_code==1){
    //     _vue.$Message.destroy()
    //     _vue.$Message.error({content: response.data.status_msg})
    //     return 
    // }  
    // 对响应数据做点什么
    return response.data;
}, (error) => {
    _vue.$Message.destroy()
    // 判断请求异常信息中是否含有超时timeout字符串
    if(error.message.includes('timeout')){   
        _vue.$Message.error({content: '网络超时'});
        return Promise.reject(error);
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});

const install = function(Vue, config = {}) {
    Vue.prototype.$request = axios
}

export {
    install,
    axios
};