import { request, config } from '@/utils'

const api = {
    home(data){
        return request.post(`${config.apiurl}/home/pc`,data)
    },

    login () {  // 登录
        return request.post(`${config.apiurl}/login`)
    },
 
    userInfo (params) {  // 获取登录用户信息
        return request.post(`${config.apiurl}/user/info`,params)
    },

    loginout () {  // 获取登录用户信息
        return request.post(`${config.apiurl}/logout`)
    },

    change_login (data) {  // 切换登录
        return request.post(`${config.apiurl}/user/company/change/login`, data)
    },

    sms (data) {  // 发送短信验证码
        return request.post(`${config.apiurl}/sms/send`, data)
    },
    //获取首页数据
    panel (data) {  
        return request.post(`${config.apiurl}/home/panel`, data)
    },
    personinfo (data) {  // 个人实名认证 - 获取应用内个人实名信息
        return request.post(`${config.yun_domain}/realname/person/info`, data)
    },
   
}

export default api;